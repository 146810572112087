<template>
  <div :class="{ 'c-dark-theme': true, animated: false, fadeIn: true }">
    <b-tabs v-model="activeTab">
      <b-tab title="유류관리 일일정산 보고서">
        <b-card>
          <template #header>
            <b-icon-server /> <strong>유류관리 일일정산 보고서</strong>
            <div class="card-header-actions">
              <small class="text-muted">유류관리 일일정산 보고서를 조회합니다.</small>
            </div>
          </template>
          <b-row class="mb-2">
            <b-col>
              <b-input-group size="sm" class="mb-3">
                <b-form-input type="date" v-model="selectedDate" @input="fetchDailyReport" class="dark-input"></b-form-input>
              </b-input-group>
            </b-col>
            <b-col sm="2" class="text-right">
              <b-input-group size="sm" class="mb-3">
                <b-button-group>
                  <b-button size="sm" variant="primary" @click="fetchDailyReport"><b-icon-arrow-repeat /></b-button>
                </b-button-group>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col ref="excelGrid">
              <ReportViewerDaily :reportData="dailyReport" />
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="탱크로리 조회">
        <b-card>
          <template #header>
            <b-icon-server /> <strong>탱크로리 정보 조회</strong>
            <div class="card-header-actions">
              <small class="text-muted">탱크로리 정보를 조회합니다.</small>
            </div>
          </template>
          <b-row class="mb-2">
          </b-row>
          <b-row>
            <b-col>
              <ReportViewerTankLorry />
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import ReportViewerDaily from './ReportViewerDaily';
import ReportViewerTankLorry from './ReportViewerTankLorry';

export default {
  components: {
    ReportViewerDaily,
    ReportViewerTankLorry,
  },
  data() {
    return {
      activeTab: 0,
      downloadModalShow: false,
      selectedDate: moment().format('YYYY-MM-DD'),
      searchMid: '',
      dailyReport: null,
      // tankOpts: [],
      downloadReason: '',
      startTime: '00:00',
      endTime: '23:59',
    };
  },
  methods: {
    fetchDailyReport() {
      axios.get(`/api/inventory/report/daily`, {
        params: {
          date: this.selectedDate,
          startTime: this.startTime,
          endTime: this.endTime
        }
      }).then(response => {
        this.dailyReport = response.data;
      });
    },
    // async fetchTankOptions() {
    //   try {
    //     const response = await apiCall('get', '/api/consumption/tank-table-init');
    //     this.tankOpts = response.data.result.opts;
    //   } catch (error) {
    //     console.error('Error fetching tank options:', error);
    //   }
    // },
    showDownloadModal() {
      this.downloadModalShow = true;
    },
    resetDownloadReason() {
      this.downloadReason = '';
    },
  },
  mounted() {
    this.fetchDailyReport();
    // this.fetchTankOptions();
  },
  watch: {
    activeTab(newValue) {
      if (newValue === 0) {
        this.selectedDate = moment().format('YYYY-MM-DD');
        this.fetchDailyReport();
      } else {
        // this.fetchTankOptions();
      }
    }
  }
}
</script>

<style scoped>
.toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.report-viewer {
  white-space: pre-wrap;
  background: #2f2f2f;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
}
.dark-input,
.dark-timepicker {
  background-color: #2f2f2f;
  color: #fff;
  border: 1px solid #565656;
  margin-right: 10px;
}
.dark-input::placeholder,
.dark-timepicker::placeholder {
  color: #aaa;
}
.dark-input .dropdown-menu,
.dark-timepicker .dropdown-menu {
  background-color: #2f2f2f;
  color: #fff;
  border: 1px solid #565656;
}
.dark-input .dropdown-item,
.dark-timepicker .dropdown-item {
  background-color: #2f2f2f;
  color: #fff;
}
</style>
